@import url('https://fonts.googleapis.com/css2?family=Playfair:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&family=Playfair:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&family=Montserrat:ital,wght@0,400;0,600;1,400&family=Playfair:wght@400;500;600;700&display=swap');
html,

body,

#root,

.app {

font-family: 'Libre Baskerville', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Playfair', serif;
}



.nav-item:hover {
    color:#8976a2;
    cursor: pointer;
  }

.inputHover:hover{
  border-color: #DCC4B9;
}

.card {
  flex-shrink: 0;
  width: 300px;
  height: 200px;
  border-radius: 10px;
  margin-left: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.container {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  padding: 20px 0;
  margin-top: 60px;
}

::-webkit-scrollbar {
  width: 0px;
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
}

.slick-slide {
	padding: 0 8px;
	box-sizing: border-box;
 }

ul.slick-dots {
  bottom: auto;
}



/* .glass-card {
background: transparent;
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
background-image: linear-gradient(99deg, rgba(119, 95, 157, 0.663) 2%, rgba(192, 195, 221, 0) 95%);
backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 0px;
border: 1px solid rgba(248, 248, 248, 0.392);

} */



/* .glass-card2 {
  background: transparent;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  background-image: linear-gradient(240deg, rgb(138, 122, 165) 2%, rgba(192, 195, 221, 0) 95%);
  backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 1px;
  border: 1px solid rgba(248, 246, 246, 0.398);
  
  } */

.small-glass-card {
  background: rgba(255, 255, 255, 0.037);
  /* box-shadow: 0 8px 32px 0 rgba(56, 64, 177, 0.37); */
-webkit-backdrop-filter: blur(10px);
backdrop-filter: blur(10px);
/* border: 1px groove rgba(242, 243, 237, 0.77); */
border-radius: 2px;
}
/* old */
/* .gradient-background {
  background: linear-gradient(240deg,#27047f,#efad4b);
  background-size: 180% 180%;
  animation: gradient-animation 8s ease infinite;
} */




/* @keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */

/* .gradient-background :hover {
  background: linear-gradient(240deg,#27047f,#efad4b);
  background-size: 180% 180%;
  animation: gradient-animation 14s ease infinite;
} */

.gradient-background {
  background-color:#121113;
  /* background: linear-gradient(180deg,#100448,#010101);  */
  background-size: 120% 120%;
  animation: gradient-animation 2s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.card-spotlight-effect {
  position: relative;
  max-width: 24rem;
  overflow: hidden;
  border-radius: 0.575rem;
  /* border: 1px solid #374151; */
  /* background: #1a191b; */
  /* background-image: linear-gradient(to right, #1a1a1b, #232530); */
  /* padding: 4rem 2rem; */
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.spotlight {
  pointer-events: none;
  position: absolute;
  inset: -1px;
  opacity: 0;
  transition: opacity 300ms;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b82f6;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.greeting {
  margin-top: 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
}

.description {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #6b7280;
}
